.product-page-container {
  margin-left: 25px;
  margin-right: 25px;
  padding-bottom: 20px;
  height: calc(100vh - 210px);
}

.analytics-container {
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  margin-left: 5%;
  height: 100%;
  max-width: 90%;
}

.product-chart-container {
  border: 1px solid rgb(250, 248, 248);
  width: 100%;
  height: 100%;
}

.product-information-text {
  height: 50px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 0px;
  margin-bottom: 0px;

  background-color: #87191A;

  font-size: large;
  font-weight: bolder;
  color: white;

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}

.product-information-icon {
  font-size: 10rem;
  flex: 1;
}

.tab {
  display: inline-block;
  background-color: white;
  color: #282c34;
  padding: 5px 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  border: 1px solid white;
  transition: box-shadow 0.3s;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.tab:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.tab.active {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  color: #87191A;
  font-weight: bolder;
}

.product-info-disclaimer {
  font-size: 13px;
  margin-left: 5px;
}

@media (max-width: 600px) {
  .product-page-container {
    margin-left: 0px;
    margin-right: 0px;
    height: calc(100vh - 235px);
  }

  .analytics-container {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }

  .product-information-text {
    height: 18px;
    font-size: 9px;
  }

  .product-information-icon {
    font-size: 1.4rem;
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .tab {
    font-size: 10px;
  }

  .product-info-disclaimer {
    font-size: 11px;
  }
}