.product-carousel-container {
  position:relative;
  margin-left: 50px;
  margin-right: 50px;
  padding-bottom: 20px;
  margin-top: -350px;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 500px;
}

.product-carousel-box {
  flex: 0.9;
  width:100%;
}

@media (min-width: 1400px) {
  .product-carousel-container {
    margin-top: -350px;
  }
}

/* Styles for smaller screens */
@media (max-width: 1350px) {
  .product-carousel-container {
    margin-top: -200px;
  }
}

/* Styles for smaller screens */
@media (max-width: 1150px) {
  .product-carousel-container {
    margin-top: -150px;
  }
}

.product-carousel-header {
  background-color: #ffffff;

  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 150px;
  width:100%;

  text-align: left;
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
}

.product-carousel-box {
  margin-top: -5px;
}

.slick-slide {
  display: flex;
  justify-content: space-evenly;
}

.slick-slide > div {
  max-width: 450px;
  min-height: 350px;
}

/* Styles for smaller screens */
@media (max-width: 600px) {
  .product-carousel-container {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: -50px;
    margin-bottom: 60px;
    height: 200px;
  }

  .product-carousel-header {
    padding-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    margin-left: 0px;

    font-size: medium;
    text-align: center;
  }

  .slick-slide > div{
    min-height: 170px;
  }
  
  .slick-prev:before {
    color:#87191A;
    margin-right: -5px;
  }
   
  .slick-next:before {
    color: #87191A;
    margin-left: -5px;
  }
}