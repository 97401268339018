.all-product-page-container {
  background-color: #fefefe;
  margin-top: -40px;
  padding-top: 20px;
}

.all-product-grid-container {
  margin-left: 50px;
  margin-right: 50px;
}

.all-products-grid {
  margin-top: -15px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.all-products-grid-header {
  background-color: #ffffff;
  margin-top: 35px;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
}

.group-heading {
  font-size: 1.25rem;
  margin-left: 20px;
  font-weight: normal;
}

.all-products-card {
  flex: 0 0 calc(25% - 20px); /* Adjust the width of each card */
  margin: 10px; /* Add spacing between cards */
  background-color: #ffffff;
  cursor: pointer;
  border: 1px solid #fefefe;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
  width:90%;
  transition: box-shadow 0.3s;
}

.all-products-card:hover {
  box-shadow: 2px 15px 15px rgba(0, 0, 0, 0.1);
}

.all-product-grid-card-icon {
  font-size: 10rem;
  flex: 1;
}

.all-product-grid-card-title {
  font-weight: normal;
  max-width: 100%;
  text-align: center;
}

.all-product-grid-card-release-date {
  font-weight: normal;
  max-width: 100%;
  text-align: center;
}

/* Styles for smaller screens */
@media (max-width: 600px) {

  .all-products-grid-header {
    padding-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 30px;
    margin-left: 0px;

    font-size: medium;
    text-align: center;
  }

  .all-products-grid {
    grid-template-columns: repeat(auto-fit, 49%);
    grid-gap: 1px;
    align-items: center;
  }

  .group-heading {
    font-size: 14px;
    margin-left: -5px;
  }

  .all-products-card {
    height: 160px;
  }

  .all-product-grid-card-icon {
    width: 100%;
    font-size: 2.5rem;
  }

  .all-product-grid-card-title {
    font-size: 9px;
    font-weight: bold;
  }

  .all-product-grid-card-release-date {
    font-size: 8px;
    margin-bottom: 0px;
  }
}