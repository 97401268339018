.product-grid-container {
  margin-top: -20px;
  margin-left: 50px;
  margin-right: 50px;
  position:relative;
  z-index: 1;
}

.product-grid-header {
  background-color: #ffffff;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 30px;
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.product-grid {
  margin-top: -15px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.product-grid-card {
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.2);
  padding: 5px;
  text-align: center;
  transition: box-shadow 0.3s;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.product-grid-card:hover {
  box-shadow: 2px 15px 15px rgba(0, 0, 0, 0.1);
}

.product-grid-card-icon {
  font-size: 10rem;
  flex: 1;
}

.product-grid-card-title {
  font-weight: bold;
  max-width: 100%;
  text-align: center;
}

@media (max-width: 600px) {
  .product-grid {
    grid-template-columns: repeat(auto-fit, 32%);
    grid-gap: 5px;
    align-items: center;
    margin-left: 0.5%;
  }

  .product-grid-header {
    font-size: 15px;
    text-align: center;
  }

  .product-grid-card {
    height: 120px;
  }

  .product-grid-card-icon {
    font-size: 2.8rem;
  }

  .product-grid-card-title {
    padding-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    
    margin-left: 0px;
    margin-bottom: 0px;

    font-size: 10px;
    text-align: center;
  }
}