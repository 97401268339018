.light-text-heading {
    background-color: #ffffff;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    
    font-size: 24px;
    text-align: left;
    font-weight: bold;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
}

.light-text-heavy {
    display: flex;
    align-items: center;
    justify-content: center;
    
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
}

.normal-strong-text {
    font-size: var(--chakra-fontSizes-md);
    font-weight: 600;
    font-size: 1.2rem;
}

.light-text {
    display: flex;
    align-items: center;
    justify-content: center;
    
    color: #ffffff;
    text-align: center;
    text-decoration: none;
}

.light-text-link {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    text-align: center;
    text-decoration: none;
}

.light-text-link:hover {
    text-decoration: underline;
}

.dark-text-heavy {
    width: 100%;
    
    color: #87191A;
    text-align: left;
    text-decoration: none;
    font-weight: bold;
    font-size: 24px;
}


/* Styles for smaller screens */
@media (max-width: 600px) {
    .light-text-heading {
        font-size: medium;
    }
    .light-text-heavy {
        font-size: medium;
    }

    .light-text {
        font-size: small;
    }

    .light-text-link {
        font-size: small;
    }

    .dark-text-heavy {
        font-size: 15px;
    }

    .normal-strong-text {
        font-size: var(--chakra-fontSizes-md);
        font-weight: 700;
        font-size: 0.8rem;
    }
}