.footer {
    text-align: center;
}

.footer-container {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: #ffffff;
    background-color: #87191A;
    height: 120px;
}

.icon-links {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    margin-right: 0.5rem;
    font-size: 1.2rem;
}

.social-icon {
    margin-right: 5px;
}