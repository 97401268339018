.news-slider-card {
    position: relative;
    cursor: pointer;
}

/* Styles for smaller screens */
@media (max-width: 600px) {
    .news-slider-card {
        position: relative;
        cursor: pointer;
    }
}
  
  /* Styles for larger screens */
  @media (min-width: 600px) {
    .news-slider-card {
        position: relative;
        cursor: pointer;
    }
}
  
.news-slider-card::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 55%, #fefefe 100%);
    pointer-events: none;
}
  
.news-slider-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
