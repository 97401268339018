.news-page-container {
  background-color: #fefefe;
  margin-top: -40px;
  padding-top: 40px;
}

.news-grid-container {
    background-color: #fefefe;
    margin-left: 50px;
    margin-right: 50px;
  }
  
  .news-grid {
    margin-top: -15px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
  }
  
  .news-grid-header {
    background-color: #ffffff;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 30px;
    font-size: 24px;
    text-align: left;
    font-weight: bold;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .news-card {
    background-color: #ffffff;
    cursor: pointer;
    border: 1px solid #fefefe;
    border-radius: 5px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
    transition: box-shadow 0.3s;
  }
  
  .news-card:hover {
    box-shadow: 2px 15px 15px rgba(0, 0, 0, 0.1);
  }
  
  .news-card img {
    max-width: 100%;
  }
  
  /* Styles for smaller screens */
@media (max-width: 600px) {

  .news-grid-header {
    padding-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    margin-left: 0px;

    font-size: medium;
    text-align: center;
  }
}