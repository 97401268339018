.search-bar {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  position: relative;
  width: 100%;
}

@media (max-width: 600px) {
  .search-bar {
    margin-right: 0px;
  }
}

.search-button {
  padding: 0px;
  background-color: white;
  margin-left: -30px;
  height: 34.5px;
  border: none;
  /* cursor: pointer; */
}

.search-icon {
  display: inline-block;
  color: #87191A;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.search-bar input[type="text"] {
  flex: 1;
  padding: 8px;
  border: none;
  outline: none;
  font-size: 16px;
  border-radius: 4px;
  margin-right: 8px;
}

.search-bar button {
  display: inline-block;
  background-color: white;
  color: #111;
  padding: 8px 16px;
  border-radius: 4px;
  /* cursor: pointer; */
  min-width: 40px;
  border: none;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.search-bar-button:hover {
  background-color: #f8ca5f;
}

.autocomplete-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  padding: 0;
  margin-top: 4px;
}

.autocomplete-dropdown li {
  list-style-type: none;
  padding: 8px;
  text-align: left;
  font-weight: 500;
  color: #000;
  cursor: pointer;
}

.autocomplete-dropdown li:hover {
  background-color: #f0f0f0;
}
