.top-bar-container {
  z-index: 100;
  height: 75px;
  width:100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #87191A;
  position: relative;
}

.top-bar {
  width: 95%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .top-bar {
    height: 100px;
    width: 100%;
    padding: 0 0px;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }
}

.top-bar-left {
  display: flex;
  align-items:center;
  margin-left: 0px;
}

.top-bar-middle {
  flex-grow: 1;
}

.top-bar-button-container {
  width: 100%;
  margin-left: 0px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content:center;
}

.home-button {
  font-family: 'monaco';
  background-color:#ffffff;
  color: #87191A;
  padding: 5px 5px;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 22px;
  font-weight: bold;

  min-width: 80px;
  
  border: thin;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.2);
  transition: box-shadow 0.3s;
}

@media (max-width: 600px) {
  .home-button {
    min-width: 15px;
    font-size: 12px;
    margin-top: 5px;
  }

  .top-bar-container {
    height: 100px;
  }
}

.home-button:hover {
  box-shadow: 2px 15px 15px rgba(0, 0, 0, 0.1);
}

.top-bar-button {
  background-color: #87191A;

  min-width: 80px;
  padding: 17px 4px;
  margin-left: 5px;

  display: inline-block;
  border-radius: 2px;
  border: thin;
  cursor: pointer;
  
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
}

@media (max-width: 600px) {
  .top-bar-button {
    width: 12px; 
    font-size: 12px;
    margin-top: 5px;
    height: 45px;
    padding: 0px 0px;
  }
}

.top-bar-button:hover {
  border: thin solid white;
}

.profile-button {
  display: inline-block;
  background-color: #87191A;
  color: white;
  border-radius: 2px;
  padding: 17px 4px;
  margin-left: 15px;
  margin-right: 5px;
  cursor: pointer;
  min-width: 80px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  border: thin solid #87191A;
}

.profile-button:hover {
  border: thin solid white;
}

.profile-button-top-line {
  font-size: 12px;
  text-align: left;
  font-weight: normal;
}

.profile-button-bottom-line {
  font-size: 13px;
  text-align: left;
  font-weight: bold;
  text-align: center;
}