.news-carousel-container {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: -250px;
    position: relative;
    z-index: 0;
}

/* Styles for larger screens */
@media (min-width: 840px) {
  .news-carousel-container {
    margin-top: -250px;
  }
}

/* Styles for smaller screens */
@media (max-width: 840px) {
  .news-carousel-container {
    margin-top: -90px;
  }
}

@media (max-width: 600px) {
  .news-carousel-container {
    margin-top: -40px;
  }
}
  
.news-carousel-box {
  margin-top: -5px;
  padding-bottom: 30px;
  width: 100%;
}

/* Styles for smaller screens */
@media (max-width: 600px) {
  .news-carousel-container {
    margin-left: 0px;
    margin-right: 0px;
  }

  .news-carousel-box {
    margin-top: -5px;
    padding-bottom: 30px;
    width: 100%;
  }
}
  
.slick-slide {
  display: flex;
  justify-content:center
}

.slick-slide > div {
  width: 100%;
  max-width: 100%;
  max-height: max-content;
}