@media only screen and (min-width: 768px) {
    .nav-mobile {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .nav-list {
        display: none;
    }
}

.active-link {
    text-decoration: "none";
    background-color: "mainRed.100";
    color: "mainRed.500";
    font-weight: 600;
}

.inactive-link {
    text-decoration: "none";
}

.image-panel-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}



@media (max-width: 1200px) {
    .image-panel-container {
        flex-direction: column;
    }
}

.image-panel {
    flex: 50%;
    max-width: 400px;
    padding: 24px;
    margin: "0 auto 0 auto";
}