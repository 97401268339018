.analytics-overview-container {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.product-information-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.date-header {
    font-weight: bold;
    text-align: left;
}