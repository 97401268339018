.carousel-product-card {
  background-color: #ffffff;
  
  margin-bottom: 10px;
  margin-left: 5%;
  height: 400px;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  cursor: pointer;
  border: 1px solid #fefefe;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.2);
  transition: box-shadow 0.3s;
}

.carousel-product-card-sign {
  padding-top: 45px;
  align-items: center;
  flex: 0.5;
  width:100%;
}



@media (min-width: 840px) {
  .carousel-product-card {
    width: 90%;
  }
}

@media (max-width: 840px) {
  .carousel-product-card {
    width: 80%;
  }
}

.carousel-product-card:hover {
  box-shadow: 2px 15px 15px rgba(0, 0, 0, 0.1);
}

.carousel-product-card-headers-top-container {
  flex: 0.5;
  padding-top: 10px;
  height: 100%;
}

.carousel-product-card-headers-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  margin-top: 0px;
  width: 100%;
  height: 100%;
}

.carousel-product-card-headers-inner-container h4 {
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 25px;
  height: 15px;
}

.carousel-product-card-headers-inner-container h5 {
  font-weight: normal;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  width: fit-content;
  padding: 2px;
  color: white;
  background-color: #87191A;
}

.carousel-product-card-icon {
  width: 100%;
  
  text-align: center;
  font-size: 10rem;
}

@media (max-width: 600px) {
  .carousel-product-card {
    width: 85%;
    height: 180px;
    padding-bottom: 5px;
  }

  .carousel-product-card-icon {
    font-size: 4rem;
  }

  .carousel-product-card-headers-top-container {
    font-size: 9px;
    height: 10px;
    margin: 0px;
    margin-top: 0px;
    margin-bottom: 0px;

    flex:0.7;
  }

  .carousel-product-card-sign {
    padding-top: 5px;
    min-height: 70px;
    flex: 0.3;
  }

  .carousel-product-card-headers-inner-container h4 {
      font-weight: bold;
      max-width: 100%;
      text-align: center;
      font-size: 10px;
  }
  
  .carousel-product-card-headers-inner-container h5 {
      max-width: 100%;
      margin-left: 0px;
      margin-bottom: 0px;
      text-align: center;
      background-color: #87191A;
      color: white;
      font-size: 10px;
      font-weight: bold;
  }
}